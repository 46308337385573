import { deleteApiWrapper, getApiWrapper, postApiWrapper } from './token-wrapper-function.js';

export const getAllTemplates = async () => {
  const result = await getApiWrapper(`/api/message_templates`);
  return result;
}

export const postTemplate = async (data) => {
  const url = `/api/message_templates`;
  const result = await postApiWrapper(url, data);
  return result;
}

export const getTemplateByName = async (name) => {
  const result = await getApiWrapper(`/api/message_templates/${name}`);
  return result;
}

export const updateTemplate = async (name, data) => {
  const url = `/api/message_templates/${name}`;
  const result = await postApiWrapper(url, data);
  return result;
}

export const deleteTemplate = async (name) => {
  const result = await deleteApiWrapper(`/api/message_templates/${name}`);
  return result;
}

export const userDetail = async (nodeId) => {
  const result = await getApiWrapper(`/api/template_variables/${nodeId}`);
  return result;
}

export const newRegisteredUsers = async () => {
  const result = await getApiWrapper(`/api/registered_users`);
  return result;
}